<template>
  <div class="home">
    <full-page ref="fullpage" :options="options" id="fullpage">
      <div class="section section1">
        <div class="header animate__animated">
          <div class="wrapper">
            <div class="logo">
              <router-link to="/">长春凯达</router-link>
            </div>
            <Menu />
          </div>
        </div>
        <el-carousel class="swiper-list" :interval="5000" arrow="always">
          <el-carousel-item v-for="(item, index) in swiperList" :key="index">
            <div
              class="swiper-item"
              :style="{ backgroundImage: 'url(' + item.covers[0].url + ')' }"
            ></div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="section section2">
        <div class="title animate__animated">
          <h2>走进凯达</h2>
        </div>
        <div class="content">
          <div class="video">
            <video muted autoplay="autoplay" controls width="800">
              <source
                src="https://file.smallpay.com/group1/M00/39/46/wKis0GO2Jj2AUlc-AfprSti0Uqc758.mp4"
                type="video/mp4"
              />
              抱歉，您的浏览器不支持内嵌视频。
            </video>
          </div>
        </div>
        <div class="more animate__animated">
          <router-link to="/about/qyjj?type=qyjj">了解更多</router-link>
        </div>
      </div>
      <div class="section section3">
        <div class="title animate__animated">
          <h2>凯达资讯</h2>
        </div>
        <div class="content animate__animated">
          <ul class="news-list">
            <li
              v-for="(item, index) in newsList"
              :key="index"
              @click="newsGo(item._id)"
            >
              <img :src="item.covers[0].url" />
              <div class="text">
                <h3>{{ item.title }}</h3>
                <p>{{ item.createdAt.substring(0, 10) }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="more animate__animated">
          <router-link to="/list?type=hkng3zuaD6tgRh8wi">了解更多</router-link>
        </div>
      </div>
      <div class="section section4">
        <div class="title animate__animated">
          <h2>凯达建设</h2>
        </div>
        <div class="content animate__animated">
          <ul class="project-list">
            <li
              v-for="(item, index) in projectList"
              :key="index"
              @click="listGo(item.type)"
            >
              <img :src="item.image" />
              <h3>{{ item.name }}</h3>
            </li>
          </ul>
        </div>
        <div class="more animate__animated">
          <router-link to="/list?type=APpWvgSZmTHK7jre2">了解更多</router-link>
        </div>
      </div>
      <div class="section section5">
        <div class="title animate__animated">
          <h2>凯达招商</h2>
        </div>
        <div class="content" :class="investment.zoom ? 'zoom' : ''">
          <div
            class="item"
            v-for="(item, index) in investmentList"
            :key="index"
            @click="zoomIn(index)"
            :class="investment.active == index ? 'active' : ''"
          >
            <div class="item-image" :class="item.image"></div>
            <div class="item-name">
              <h3>{{ item.name }}</h3>
            </div>
            <div class="item-zoom">
              <div class="item-link" @click="listGo(item.type)"></div>
              <div class="item-close" @click.stop="zoomOut()">
                <span class="iconfont icon-close"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="more animate__animated">
          <router-link to="/list?type=XKWc77iMypXKFhgMg">了解更多</router-link>
          <router-link to="/pages/lyb?type=lyb">招商咨询</router-link>
        </div>
      </div>
      <div class="section section6">
        <div class="title animate__animated">
          <h2>凯达招聘</h2>
        </div>
        <div class="content animate__animated">
          <p>欢迎有识之士精诚合作、<br />投资兴业，共创未来！</p>
        </div>
        <div class="more animate__animated">
          <router-link to="/recruit/rcln?type=rcln">加入我们</router-link>
        </div>
      </div>
      <div class="section section7">
        <div class="title animate__animated">
          <h2>党建工作</h2>
        </div>
        <div class="content animate__animated">
          <p class="img">党建动态、反腐倡廉、专题活动</p>
        </div>
        <div class="more animate__animated">
          <router-link to="/list?type=YcY7PMyvthmQndhZY">了解更多</router-link>
        </div>
      </div>
      <div class="section section8">
        <div class="title animate__animated">
          <h2>凯达云</h2>
          <p>
            长春凯达发展有限公司以首创精神，在省内率先使用集“流程审批、电子档案、项目管理、银企直连”等<br />功能于一体的“凯达云”系统，数字化赋能长春国际汽车城建设。
          </p>
        </div>
        <div class="content">
          <div class="img"></div>
        </div>
        <div class="more animate__animated">
          <a
            class="btn"
            href="https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard"
            target="_blank"
            >立即注册</a
          >
        </div>
      </div>
      <div class="section section9">
        <Footer :bg="footerBg" />
      </div>
    </full-page>
    <div class="navigation">
      <ul>
        <li
          v-for="(item, index) in navigation"
          :key="index"
          :data-menuanchor="item.data"
          @click="selfGo(item.url)"
        >
          <span
            class="icon"
            :style="{ backgroundImage: 'url(' + item.image + ')' }"
          ></span>
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </div>
    <!-- <div class="widget" v-show="adJobs.show">
      <div class="ad-jobs">
        <img :src="adJobs.img" />
      </div>
      <div class="close" @click="widgetClose()">
        <span class="iconfont icon-close"></span>
      </div>
    </div> -->
  </div>
</template>

<script>
import "fullpage.js/dist/fullpage.min.css";
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";
import $ from "jquery";
export default {
  name: "Home",
  components: { Menu, Footer },
  data() {
    return {
      options: {
        anchors: [
          "page1",
          "page2",
          "page3",
          "page4",
          "page5",
          "page6",
          "page7",
          "page8",
          "page9",
        ],
        menu: ".navigation",
        afterLoad: this.afterLoad,
      },
      swiperList: [],
      newsList: [],
      projectList: [
        {
          image: require("../assets/images/kdjs-01.jpg"),
          name: "地产开发",
          type: "BdrQLqSNpbukcksXd",
        },
        {
          image: require("../assets/images/kdjs-02.jpg"),
          name: "工业厂房",
          type: "uKm6eDiJoYrAZPGxD",
        },
        {
          image: require("../assets/images/kdjs-03.jpg"),
          name: "科技创新",
          type: "FA8Xojtg4yTxsd7iN",
        },
        {
          image: require("../assets/images/kdjs-04.jpg"),
          name: "水利生态",
          type: "n84tasiTD7tZQBhyo",
        },
        {
          image: require("../assets/images/kdjs-05.jpg"),
          name: "教育文旅",
          type: "aGfjy38WcauxhSZiZ",
        },
        {
          image: require("../assets/images/kdjs-06.jpg"),
          name: "市政公用",
          type: "LSMZ4ZP532ofzGhLN",
        },
        {
          image: require("../assets/images/kdjs-07.jpg"),
          name: "景观绿化",
          type: "uSjku6koPfkKKHqAg",
        },
        {
          image: require("../assets/images/kdjs-08.jpg"),
          name: "其他项目",
          type: "APpWvgSZmTHK7jre2",
        },
      ],
      investment: {
        zoom: false,
        active: -1,
      },
      investmentList: [
        {
          image: "image-1",
          name: "新能源汽车产业园",
          type: "8h9f8jod6TmGv7avi",
        },
        {
          image: "image-2",
          name: "新技术产业园",
          type: "bP4xpgw5GvN5Li8uB",
        },
        {
          image: "image-3",
          name: "青年人才服务园",
          type: "KYeQPFqhKjTJZFqgG",
        },
        {
          image: "image-4",
          name: "人力资源产业园",
          type: "gJywirRF6jgZBFMgc",
        },
      ],
      footerBg: "none",
      navigation: [
        {
          data: "page1",
          url: "#page1",
          image: require("../assets/images/icon-nav-01.png"),
          name: "首页",
        },
        {
          data: "page2",
          url: "#page2",
          image: require("../assets/images/icon-nav-02.png"),
          name: "凯达",
        },
        {
          data: "page3",
          url: "#page3",
          image: require("../assets/images/icon-nav-03.png"),
          name: "资讯",
        },
        {
          data: "page4",
          url: "#page4",
          image: require("../assets/images/icon-nav-04.png"),
          name: "建设",
        },
        {
          data: "page5",
          url: "#page5",
          image: require("../assets/images/icon-nav-05.png"),
          name: "招商",
        },
        {
          data: "page6",
          url: "#page6",
          image: require("../assets/images/icon-nav-06.png"),
          name: "招聘",
        },
        {
          data: "page7",
          url: "#page7",
          image: require("../assets/images/icon-nav-07.png"),
          name: "党建",
        },
        {
          data: "page8",
          url: "#page8",
          image: require("../assets/images/icon-nav-08.png"),
          name: "企业云",
        },
        {
          data: "page9",
          url: "#page9",
          image: require("../assets/images/icon-nav-09.png"),
          name: "链接",
        },
      ],
      adJobs: {
        img: require("../assets/images/chunjie.jpg"),
        url: "https://s2ngewehvzjp9e2kq.icloudportal.com/cms/recruitment/recruitment_joblist?batchtypeid=pivTFzqwpi8dsYGvB&location=5ongPPN9WzMuNQzEp",
        show: true,
      },
    };
  },
  created() {
    this.getSwiperList();
    this.getNewsList();
    // this.$loadLanguage(localStorage.getItem("lang"));
  },
  methods: {
    afterLoad(before, after) {
      if (before.index == 0) {
        // 第一屏
        $(".section1 .header").removeClass("animate__fadeInDown").hide();
      } else if (before.index == 1) {
        // 第二屏
        $(".section2 .title").removeClass("animate__fadeInDown").hide();
        $(".section2 .more").removeClass("animate__fadeInUp").hide();
      } else if (before.index == 2) {
        // 第三屏
        $(".section3 .title").removeClass("animate__fadeInDown").hide();
        $(".section3 .content").removeClass("animate__fadeInUp").hide();
        $(".section3 .more").removeClass("animate__fadeInUp").hide();
      } else if (before.index == 3) {
        // 第四屏
        $(".section4 .title").removeClass("animate__fadeInDown").hide();
        $(".section4 .content").removeClass("animate__zoomIn").hide();
        $(".section4 .more").removeClass("animate__fadeInUp").hide();
      } else if (before.index == 4) {
        // 第五屏
        $(".section5 .title").removeClass("animate__fadeInDown").hide();
        $(".section5 .more").removeClass("animate__fadeInUp").hide();
        $(".image-bezier").removeClass("image-bezier").hide();
        $(".section5 .item-name h3").removeClass("text-bezier").hide();
      } else if (before.index == 5) {
        // 第六屏
        $(".section6 .title").removeClass("animate__fadeInDown").hide();
        $(".section6 .content").removeClass("animate__zoomIn").hide();
        $(".section6 .more").removeClass("animate__fadeInUp").hide();
      } else if (before.index == 6) {
        // 第七屏
        $(".section7 .title").removeClass("animate__fadeInDown").hide();
        $(".section7 .content").removeClass("animate__zoomIn").hide();
        $(".section7 .more").removeClass("animate__fadeInUp").hide();
      } else if (before.index == 7) {
        // 第八屏
        $(".section8 .title").removeClass("animate__fadeInDown").hide();
        $(".section8 .more").removeClass("animate__fadeInUp").hide();
      } else if (before.index == 8) {
        // 第九屏
        $(".section9 .links").removeClass("animate__fadeInDown").hide();
        $(".section9 .qrcode").removeClass("animate__fadeInUp").hide();
        $(".section9 .copyright").removeClass("animate__fadeInUp").hide();
      }
      if (after.index == 0) {
        // 第一屏
        $(".section1 .header").addClass("animate__fadeInDown").show();
      } else if (after.index == 1) {
        // 第二屏
        $(".section2 .title").addClass("animate__fadeInDown").show();
        $(".section2 .more").addClass("animate__fadeInUp").show();
      } else if (after.index == 2) {
        // 第三屏
        $(".section3 .title").addClass("animate__fadeInDown").show();
        $(".section3 .content").addClass("animate__fadeInUp").show();
        $(".section3 .more").addClass("animate__fadeInUp").show();
      } else if (after.index == 3) {
        // 第四屏
        $(".section4 .title").addClass("animate__fadeInDown").show();
        $(".section4 .content").addClass("animate__zoomIn").show();
        $(".section4 .more").addClass("animate__fadeInUp").show();
      } else if (after.index == 4) {
        // 第五屏
        $(".section5 .title").addClass("animate__fadeInDown").show();
        $(".section5 .more").addClass("animate__fadeInUp").show();
        $(".image-1").addClass("image-bezier").show();
        $(".section5 .item-name h3").eq(0).addClass("text-bezier").show();
        setTimeout(function () {
          $(".image-2").addClass("image-bezier").show();
          $(".section5 .item-name h3").eq(1).addClass("text-bezier").show();
        }, 300);
        setTimeout(function () {
          $(".image-3").addClass("image-bezier").show();
          $(".section5 .item-name h3").eq(2).addClass("text-bezier").show();
        }, 600);
        setTimeout(function () {
          $(".image-4").addClass("image-bezier").show();
          $(".section5 .item-name h3").eq(3).addClass("text-bezier").show();
        }, 900);
      } else if (after.index == 5) {
        // 第六屏
        $(".section6 .title").addClass("animate__fadeInDown").show();
        $(".section6 .content").addClass("animate__zoomIn").show();
        $(".section6 .more").addClass("animate__fadeInUp").show();
      } else if (after.index == 6) {
        // 第七屏
        $(".section7 .title").addClass("animate__fadeInDown").show();
        $(".section7 .content").addClass("animate__zoomIn").show();
        $(".section7 .more").addClass("animate__fadeInUp").show();
      } else if (after.index == 7) {
        // 第八屏
        $(".section8 .title").addClass("animate__fadeInDown").show();
        $(".section8 .more").addClass("animate__fadeInUp").show();
      } else if (after.index == 8) {
        // 第九屏
        $(".section9 .links").addClass("animate__fadeInDown").show();
        $(".section9 .qrcode").addClass("animate__fadeInUp").show();
        $(".section9 .copyright").addClass("animate__fadeInUp").show();
      }
    },
    getSwiperList() {
      const that = this;
      this.axios
        .get("https://s.icloudportal.com/api/siteReleasedPosts", {
          params: {
            site: "m3FntHT5iWaBQqcGW",
            type: "4ydPSuErQNSoQenWA",
          },
        })
        .then(function (response) {
          that.swiperList = response.data.data.list;
          // console.log("res", that.swiperList);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    getNewsList() {
      this.axios
        .get("https://s.icloudportal.com/api/siteReleasedPosts", {
          params: {
            site: "m3FntHT5iWaBQqcGW",
            isTop: true,
          },
        })
        .then((response) => {
          this.newsList = response.data.data.list;
          // console.log("res:", this.newsList);
        })
        .catch((error) => {
          console.log("err:", error);
        });
    },
    zoomIn(index) {
      this.investment.zoom = true;
      this.investment.active = index;
      $(".section5 .title").removeClass("animate__fadeInDown");
      $(".section5 .more").removeClass("animate__fadeInUp");
    },
    zoomOut() {
      this.investment.zoom = false;
      this.investment.active = -1;
      $(".section5 .title").addClass("animate__fadeInDown");
      $(".section5 .more").addClass("animate__fadeInUp");
    },
    newsGo(id) {
      let detail = this.$router.resolve({ path: "detail", query: { id: id } });
      window.open(detail.href, "_blank");
    },
    listGo(type) {
      let detail = this.$router.resolve({
        path: "list",
        query: { type: type },
      });
      window.open(detail.href, "_blank");
    },
    blankGo(url) {
      window.open(url, "_blank");
    },
    selfGo(url) {
      window.open(url, "_self");
    },
    widgetClose() {
      this.$nextTick(() => {
        this.adJobs.show = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
.home {
  .section {
    .title {
      opacity: 0;
      position: absolute;
      top: 12%;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 9;
      h2 {
        font-size: 34px;
        font-weight: normal;
        line-height: 40px;
      }
      p {
        font-size: 16px;
        color: #666;
        line-height: 30px;
        margin-top: 20px;
      }
    }
    .content {
      text-align: center;
    }
    .more {
      opacity: 0;
      position: absolute;
      bottom: 12%;
      left: 0;
      right: 0;
      font-size: 16px;
      text-align: center;
      a {
        margin: 0 40px;
      }
    }
  }
  .section1 {
    .header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 3;
      .wrapper {
        display: flex;
        justify-content: space-between;
        width: auto;
        padding: 10px 80px 10px;
        .logo {
          padding: 30px 0;
          a {
            display: block;
            background: url(../assets/images/logo.png) no-repeat 50% 50%;
            background-size: 145px 40px;
            width: 145px;
            height: 40px;
            text-indent: -9999px;
          }
        }
        /deep/ .drawer {
          height: 100vh;
        }
        /deep/ .drawer-hidden {
          top: 35px;
          right: 35px;
        }
      }
    }
    .swiper-list {
      height: 100%;
      z-index: 1;
      .swiper-item {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 100%;
      }
      /deep/ .el-carousel__container {
        height: 100%;
      }
      /deep/ .el-carousel__arrow {
        background-color: rgba(0, 0, 0, 0.2);
        width: 40px;
        height: 40px;
        font-size: 20px;
        i {
          font-weight: bold;
        }
      }
      /deep/ .el-carousel__arrow--left {
        left: 80px;
      }
      /deep/ .el-carousel__arrow--right {
        right: 80px;
      }
      /deep/ .el-carousel__indicators--horizontal {
        bottom: 10px;
        .el-carousel__indicator--horizontal {
          padding: 18px 5px;
          .el-carousel__button {
            height: 4px;
          }
        }
      }
    }
  }
  .section2 {
    background: url(../assets/images/bg1.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  .section3 {
    background: url(../assets/images/bg2.jpg) no-repeat 50% 50%;
    background-size: cover;
    .content {
      opacity: 0;
      .news-list {
        display: flex;
        justify-content: center;
        li {
          width: 380px;
          margin: 0 5px;
          overflow: hidden;
          cursor: pointer;
          transition: all 0.5s;
          img {
            width: 380px;
            height: 240px;
            transition: all 0.5s;
          }
          .text {
            background: #fff;
            text-align: left;
            padding: 14px 14px;
            line-height: 26px;
            h3 {
              font-size: 16px;
              font-weight: normal;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            p {
              color: #5a5657;
            }
          }
        }
        li:hover {
          box-shadow: 0 0 10px #999;
          transform: translateY(-1%);
        }
        li:hover img {
          transform: scale(1.02);
        }
      }
    }
  }
  .section4 {
    background: url(../assets/images/bg3.jpg) no-repeat 50% 50%;
    background-size: cover;
    .content {
      .project-list {
        display: flex;
        flex-wrap: wrap;
        width: 840px;
        margin: 0 auto;
        li {
          position: relative;
          width: 200px;
          margin: 5px 5px;
          cursor: pointer;
          h3 {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.4);
            font-size: 16px;
            color: #fff;
            font-weight: normal;
            line-height: 30px;
            padding: 6px 0;
          }
        }
      }
    }
  }
  .section5 {
    background: #000;
    .title {
      color: #fff;
    }
    .more {
      a {
        color: #fff;
      }
    }
    .text-bezier {
      -webkit-animation: text-bezier 4s 0.3s cubic-bezier(0.23, 1, 0.32, 1)
        forwards;
      animation: text-bezier 4s 0.3s cubic-bezier(0.23, 1, 0.32, 1) forwards;
    }
    @-webkit-keyframes text-bezier {
      0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
      }
      100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
    @keyframes text-bezier {
      0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
      }
      100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
    .image-bezier {
      -webkit-animation: image-bezier 3s cubic-bezier(0.23, 1, 0.32, 1);
      animation: image-bezier 3s cubic-bezier(0.23, 1, 0.32, 1);
    }
    @-webkit-keyframes image-bezier {
      0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
      }
      100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
    @keyframes image-bezier {
      0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
      }
      100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
    .content {
      position: relative;
      height: 100vh;
      color: #fff;
      overflow: hidden;
    }
    .content:hover .item-image:after {
      opacity: 1;
    }
    .item {
      position: absolute;
      left: 0;
      top: 0;
      width: 24.9%;
      height: 100%;
      background: #252525;
      -webkit-transition: width 0.7s, opacity 0.6s 0.7s, z-index 0s 1.3s,
        -webkit-transform 0.6s 0.7s;
      transition: width 0.7s, opacity 0.6s 0.7s, z-index 0s 1.3s,
        -webkit-transform 0.6s 0.7s;
      transition: transform 0.6s 0.7s, width 0.7s, opacity 0.6s 0.7s,
        z-index 0s 1.3s;
      transition: transform 0.6s 0.7s, width 0.7s, opacity 0.6s 0.7s,
        z-index 0s 1.3s, -webkit-transform 0.6s 0.7s;
      will-change: transform, width, opacity;
    }
    .item:hover {
      cursor: pointer;
    }
    .item-image {
      display: none;
      position: relative;
      width: 100%;
      height: 100%;
      -webkit-transition: -webkit-transform 0.6s 0.7s;
      transition: -webkit-transform 0.6s 0.7s;
      transition: transform 0.6s 0.7s;
      transition: transform 0.6s 0.7s, -webkit-transform 0.6s 0.7s;
      will-change: transform;
    }
    .item-image:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      -webkit-transition: -webkit-transform 1s;
      transition: -webkit-transform 1s;
      transition: transform 1s;
      transition: transform 1s, -webkit-transform 1s;
      -webkit-transform: translate3d(0, 0, 0) scale(1);
      transform: translate3d(0, 0, 0) scale(1);
    }
    .item.active .item-image:before {
      -webkit-transition: -webkit-transform 0.8s;
      transition: -webkit-transform 0.8s;
      transition: transform 0.8s;
      transition: transform 0.8s, -webkit-transform 0.8s;
    }
    .item-image:after {
      content: "";
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      -webkit-transition: opacity 0.5s;
      transition: opacity 0.5s;
    }
    .item:hover .item-image:after {
      opacity: 0;
    }
    .content.zoom .item-image:after {
      background: none;
      -webkit-transition: opacity 0.5s 1.4s;
      transition: opacity 0.5s 1.4s;
      opacity: 1 !important;
    }
    .item-name {
      z-index: 2;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -webkit-transition: all 0.3s 1.2s;
      transition: all 0.3s 1.2s;
    }
    .item-name h3 {
      opacity: 0;
      font-size: 24px;
      font-weight: normal;
      line-height: 40px;
    }
    .content.zoom .item-name {
      opacity: 0;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }
    .item-zoom {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: all 0.1s;
      transition: all 0.1s;
    }
    .item.active .item-zoom {
      z-index: 10;
      opacity: 1;
      pointer-events: auto;
      -webkit-transition: all 0.5s 1.4s;
      transition: all 0.5s 1.4s;
    }
    .item-close {
      z-index: -1;
      position: absolute;
      right: 40px;
      top: 40px;
      width: 50px;
      height: 50px;
      opacity: 0;
      transition: all 0s 0.45s;
      cursor: pointer;
      .icon-close {
        font-size: 50px;
        line-height: 50px;
      }
    }
    .item.active .item-close {
      z-index: 5;
      opacity: 1;
      pointer-events: auto;
      -webkit-transition: all 0s 1.4s;
      transition: all 0s 1.4s;
    }
    .item.active .item-link {
      width: 100%;
      height: 100%;
    }
    .item:nth-child(1) {
      -webkit-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
    .content.zoom .item:nth-child(1):not(.active) {
      -webkit-transform: scale(0.5) translate3d(0%, 0, 0);
      transform: scale(0.5) translate3d(0%, 0, 0);
      opacity: 0;
      -webkit-transition: opacity 0.95s, -webkit-transform 0.95s;
      transition: opacity 0.95s, -webkit-transform 0.95s;
      transition: transform 0.95s, opacity 0.95s;
      transition: transform 0.95s, opacity 0.95s, -webkit-transform 0.95s;
    }
    .item:nth-child(1) .item-image:before {
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      background-image: url(../assets/images/kdzs-01a.jpg);
    }
    .item.active:nth-child(1) .item-image:before {
      background-image: url(../assets/images/kdzs-01b.jpg);
    }
    .item:nth-child(2) {
      -webkit-transform: translate3d(101%, 0, 0);
      transform: translate3d(101%, 0, 0);
      -webkit-transform-origin: 155.2083333333% 50%;
      transform-origin: 155.2083333333% 50%;
    }
    .content.zoom .item:nth-child(2):not(.active) {
      -webkit-transform: scale(0.5) translate3d(105.2083333333%, 0, 0);
      transform: scale(0.5) translate3d(105.2083333333%, 0, 0);
      opacity: 0;
      -webkit-transition: opacity 0.95s, -webkit-transform 0.95s;
      transition: opacity 0.95s, -webkit-transform 0.95s;
      transition: transform 0.95s, opacity 0.95s;
      transition: transform 0.95s, opacity 0.95s, -webkit-transform 0.95s;
    }
    .item:nth-child(2) .item-image:before {
      -webkit-transition-delay: 0.1s;
      transition-delay: 0.1s;
      background-image: url(../assets/images/kdzs-02a.jpg);
    }
    .item.active:nth-child(2) .item-image:before {
      background-image: url(../assets/images/kdzs-02b.jpg);
    }
    .item:nth-child(3) {
      -webkit-transform: translate3d(202%, 0, 0);
      transform: translate3d(202%, 0, 0);
      -webkit-transform-origin: 260.4166666667% 50%;
      transform-origin: 260.4166666667% 50%;
    }
    .content.zoom .item:nth-child(3):not(.active) {
      -webkit-transform: scale(0.5) translate3d(210.4166666667%, 0, 0);
      transform: scale(0.5) translate3d(210.4166666667%, 0, 0);
      opacity: 0;
      -webkit-transition: opacity 0.95s, -webkit-transform 0.95s;
      transition: opacity 0.95s, -webkit-transform 0.95s;
      transition: transform 0.95s, opacity 0.95s;
      transition: transform 0.95s, opacity 0.95s, -webkit-transform 0.95s;
    }
    .item:nth-child(3) .item-image:before {
      -webkit-transition-delay: 0.2s;
      transition-delay: 0.2s;
      background-image: url(../assets/images/kdzs-03a.jpg);
    }
    .item.active:nth-child(3) .item-image:before {
      background-image: url(../assets/images/kdzs-03b.jpg);
    }
    .item:nth-child(4) {
      -webkit-transform: translate3d(303%, 0, 0);
      transform: translate3d(303%, 0, 0);
      -webkit-transform-origin: 365.625% 50%;
      transform-origin: 365.625% 50%;
    }
    .content.zoom .item:nth-child(4):not(.active) {
      -webkit-transform: scale(0.5) translate3d(315.625%, 0, 0);
      transform: scale(0.5) translate3d(315.625%, 0, 0);
      opacity: 0;
      -webkit-transition: opacity 0.95s, -webkit-transform 0.95s;
      transition: opacity 0.95s, -webkit-transform 0.95s;
      transition: transform 0.95s, opacity 0.95s;
      transition: transform 0.95s, opacity 0.95s, -webkit-transform 0.95s;
    }
    .item:nth-child(4) .item-image:before {
      -webkit-transition-delay: 0.3s;
      transition-delay: 0.3s;
      background-image: url(../assets/images/kdzs-04a.jpg);
    }
    .item.active:nth-child(4) .item-image:before {
      background-image: url(../assets/images/kdzs-04b.jpg);
    }
    .item.active {
      z-index: 10;
      width: 100%;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-transition: width 0.7s 0.7s, z-index 0s, -webkit-transform 0.6s;
      transition: width 0.7s 0.7s, z-index 0s, -webkit-transform 0.6s;
      transition: transform 0.6s, width 0.7s 0.7s, z-index 0s;
      transition: transform 0.6s, width 0.7s 0.7s, z-index 0s,
        -webkit-transform 0.6s;
    }
    .item.active .item-image {
      width: 100%;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-transition: -webkit-transform 0.6s;
      transition: -webkit-transform 0.6s;
      transition: transform 0.6s;
      transition: transform 0.6s, -webkit-transform 0.6s;
    }
    .item.active .item-image:before {
      -webkit-transition-delay: 0.6s;
      transition-delay: 0.6s;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .section6 {
    background: url(../assets/images/bg4.jpg) no-repeat 50% 50%;
    background-size: cover;
    color: #fff;
    .content {
      font-size: 34px;
      line-height: 50px;
    }
    a {
      color: #fff;
    }
  }
  .section7 {
    background: url(../assets/images/bg5.jpg) no-repeat 50% 50%;
    background-size: cover;
    .content {
      .img {
        background: url(../assets/images/bg5-1.png) no-repeat;
        width: 750px;
        height: 60px;
        text-indent: -9999px;
        margin: 0 auto;
      }
    }
  }
  .section8 {
    background: url(../assets/images/bg3.jpg) no-repeat 50% 50%;
    background-size: cover;
    .content {
      .img {
        background: url(../assets/images/yun.png) no-repeat 50% 50%;
        background-size: contain;
        width: 100%;
        height: calc(100vh - 200px);
        margin: 200px auto 0;
      }
    }
    .more {
      opacity: 0;
      bottom: 35%;
      .btn {
        display: block;
        background: #1f7be9;
        width: 200px;
        height: 46px;
        font-size: 18px;
        color: #fff;
        line-height: 46px;
        text-align: center;
        margin: 0 auto;
        border-radius: 23px;
      }
    }
  }
  .section9 {
    background: url(../assets/images/bg6.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  .navigation {
    position: fixed;
    right: 0;
    top: 50%;
    color: #fff;
    transform: translateY(-50%);
    // z-index: 99;
    ul {
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        margin: 4px 0;
        padding: 6px 8px;
        cursor: pointer;
        .icon {
          display: block;
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 20px auto;
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 12px;
          margin-left: 8px;
          opacity: 0;
        }
      }
      li.active {
        .icon {
          background-position: 0 -20px;
        }
      }
      li:hover {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        .icon {
          background-position: 0 -20px;
        }
        p {
          opacity: 1;
        }
      }
    }
  }
  .widget {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 800px;
    height: 450px;
    transform: translate(-50%, -50%);
    z-index: 9;
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      .icon-close {
        display: block;
        font-size: 30px;
        color: #fff;
        line-height: 30px;
      }
    }
  }
}
</style>
